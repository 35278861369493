/** @define Navbar */
.Navbar {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 72px;
  justify-content: space-around;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  &-container {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: 25px;
    justify-content: space-between;
    width: 90%;
  }

  &-logo {
    background-color: $accent-color;
    height: 25px;
    width: 175px;
  }

  &-list {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    width: auto;
    height: 25px;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
  }

  &-list-item {
    list-style-type: none;
    margin: 0px;
    margin-right: 40px;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: uppercase;
  }

  &-list-link {
    color: $grey-color;

    @include secondary-body();

    span {
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }

    &.is-active,
    &:hover {
      color: $secundary-color;
      transition: all 0.2s ease-in-out;

      span {
        color: $accent-color;
        opacity: 1;
      }
    }
  }
}
