.main-content {
  display: block;
  height: auto;
  margin: 0px;
  height: 100vh;
  padding: 0px;
  position: relative;
  width: 100%;
  //background-color: $third-green;
  //background-image: url('/media/cream-pixels.png');
  //background-image: url("/media/background_white_pattern.jpg");
  //background: url("/media/bg-texture.jpg") no-repeat center center / cover;
  background: url("/media/landingpage_fundo.png") no-repeat center center / cover;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}
