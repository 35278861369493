// Fonts

@font-face {
  font-family: 'carlmarxregular';
  src: url('/media/fonts/carlmarx-regular-webfont.woff2') format('woff2'),
       url('/media/fonts/carlmarx-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

$font-stack: "carlmarxregular", sans-serif;

$font-neon: "carlmarxregular", sans-serif;

// Colors
$primary-color: #272727;
$secundary-color: #ffffff;
$accent-color: #37c871;
$grey-color: #a0a0a0;
$grey-color-dark: #333333;
$grey-color-hover: #4a4a4a;
$box-shadow: 0 16px 28px 0;
$pink-color: #f2e0dc;
$blue-color: #7c849d;
$green-color: #e1efe3;
$second-green: #cfe4d0;
$third-green: #d8eced;

// Typography
@mixin main-title() {
  font-size: 5.5rem;
  line-height: 7.5rem;
  font-family: $font-title;
  font-weight: 900;

  @media only screen and (min-width: 1024px) {
    font-size: 15rem;
    line-height: 15.2rem;
  }
}

@mixin headline-h1() {
  font-size: 3.2rem;
  line-height: 3.4rem;
  font-family: $font-stack;
  font-weight: 700;
}

@mixin title-h2() {
  font-size: 2.8rem;
  line-height: 3rem;
  font-family: $font-title;
  font-weight: 700;

  @media only screen and (min-width: 1024px) {
    font-size: 4rem;
    line-height: 6rem;
  }
}

@mixin title-h3() {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-family: $font-stack;
  font-weight: 700;

  @media only screen and (min-width: 1024px) {
    font-size: 2rem;
    line-height: 2.2rem;
  }
}

@mixin title-h4() {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-family: $font-stack;
  font-weight: 700;
}

@mixin lead-text() {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-family: $font-stack;
  font-weight: 400;

  @media only screen and (min-width: 1024px) {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

@mixin body-text() {
  font-size: 1.5rem;
  line-height: 1.7rem;
  font-family: $font-stack;
  font-weight: 400;

  @media only screen and (min-width: 1440px) {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  @media only screen and (min-width: 1640px) {
    font-size: 1.8rem;
    line-height: 2rem;
  }
}

@mixin auxiliary-text() {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-family: $font-stack;
  font-weight: 400;
}

@mixin body-link() {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-family: $font-stack;
  font-weight: 400;
}

@mixin title-link() {
  font-size: 2.4rem;
  line-height: 2.6rem;
  font-family: $font-stack;
  font-weight: 700;
}

@mixin secondary-body() {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-family: $font-stack;
  font-weight: 400;
}
