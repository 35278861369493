.vignette {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 250px rgba(0, 0, 0, 0.4) inset;
  z-index: 9999;
}

.Home {
  position: relative;

  &-leaf {
    position: fixed;
    height: 150px;
    width: auto;
    z-index: 3;
    //filter: hue-rotate(300deg);

    &--top-left {
      left: -45px;
      top: -50px;
      height: 200px;
      //transform: scale(-1, -1);

      @media only screen and (min-width: 1024px) {
        left: -75px;
        top: -90px;
        height: 350px;
      }

      @media only screen and (min-width: 1440px) {
        left: -120px;
        top: -130px;
        height: 550px;
      }
    }

    &--top-right {
      right: 0px;
      top: 0px;
      transform: scaleY(-1);

      @media only screen and (min-width: 1024px) {
        height: 350px;
      }

      @media only screen and (min-width: 1440px) {
        height: 550px;
      }
    }

    &--bottom-left {
      left: 0px;
      top: calc(100vh - 150px);
      transform: scaleX(-1);

      @media only screen and (min-width: 1024px) {
        height: 350px;
        top: calc(100vh - 350px);
      }

      @media only screen and (min-width: 1440px) {
        height: 550px;
        top: calc(100vh - 550px);
      }
    }

    &--bottom-right {
      right: -25px;
      //top: calc(100vh - 100px);

      @media only screen and (max-width: 1023px) {
        bottom: -50px;
      }

      @media only screen and (min-width: 1024px) {
        right: -50px;
        height: 350px;
        top: calc(100vh - 240px);
      }

      @media only screen and (min-width: 1440px) {
        height: 450px;
        top: calc(100vh - 300px);
      }
    }
  }
}

.Bar {
  position: fixed;
  z-index: 3;
  background-color: $primary-color;

  &--top {
    top: 10px;
    width: calc(100% - 20px);
    height: 15px;
    left: 10px;
  }

  &--left {
    top: 10px;
    width: 15px;
    height: calc(100vh - 20px);
    left: 10px;
  }

  &--bottom {
    top: calc(100vh - 25px);
    width: calc(100% - 20px);
    height: 15px;
    left: 10px;
  }

  &--right {
    top: 10px;
    width: 15px;
    height: calc(100vh - 20px);
    right: 10px;
  }
}

.Home-block {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;

  @media only screen and (min-width: 1024px) {
    height: 100vh;
    padding-top: 0;
    justify-content: center;
  }
}

.Logo {
  //width: auto;
  //height: 100%;
  width: 100%;
  height: auto;
 

  @media only screen and (min-width: 1024px) {
    width: 50%;
    height: auto;
  }

  &-wrapper {
    width: 100%;
    height: 200px;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    //margin-bottom: 15px;
    margin-bottom: 0px;

    @media only screen and (min-width: 1024px) {
      height: 350px;
      margin-bottom: 50px;
      margin-bottom: 0px;
    }
  }
}

.Buttons-wrapper {
  width: 80%;
  height: 260px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: 1024px) {
    height: auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 150px;
  }

  @media only screen and (min-width: 1280px) {
    width: 70%;
    max-width: 1000px;
  }
}

.button {
  pointer-events: auto;
  cursor: pointer;
  background: #e7e7e7;
  border: none;
  padding: 1.5rem 3rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  font-size: 3rem;
  letter-spacing: 1.5px;
  font-family: $font-neon;
  text-transform: uppercase;
  //font-weight: 700;

  @media only screen and (min-width: 1024px) {
    //font-size: 4rem;
    font-size: 4.5rem;
  }
}

.button::before,
.button::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.button::before {
  animation: rotateIt 10s linear infinite;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAUElEQVQoU3VPSQ4AMQiS/z/aBiMNNDNeXEBQoDL66dkG52/hzlmsSqMKrAVCoOXuxR3TFVSIIJmzsFvGOMlust3iivmQd/6+3/NlN4paIOEADPIUD/MQvy0AAAAASUVORK5CYII=);
  content: "";
  width: 300%;
  height: 300%;
  top: -100%;
  left: -100%;
  z-index: -1;
  opacity: 0;
  transform-origin: 50% 50%;
  transition: opacity 0.3s;
}

.button--last {
  margin-right: 0px;
  margin-bottom: 0px;
}

.button--pandora {
  background: $primary-color;
  background: transparent;
  //font-weight: 700;
  padding: 0;
  border-radius: 20px;
  color: $primary-color;
  color: rgba(243, 222, 206, 1);
  border: 2px solid rgba(0, 66, 13,1);
}

.button--pandora span {
  display: block;
  //background: $secundary-color;
  //background: url("/media/bg-texture.jpg") no-repeat center center / cover;
  /*padding: 1.5rem 8rem;*/
  background-color: rgba(0, 66, 13,1);

  width: 250px;
  height: 65px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border: 1px solid $primary-color;
  border-radius: 20px;
  transform: translate3d(-4px, -4px, 0);
  transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);

  @media only screen and (min-width: 1024px) {
    width: 300px;
    height: 85px;
  }
}

.button--pandora:hover span {
  transform: translate3d(-8px, -8px, 0);
}
